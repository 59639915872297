import earcL from './images/ClientLogos/Earc.png'
import encoreL from './images/ClientLogos/Encore.png'
import jetwaysL from './images/ClientLogos/Jetways.png'
import answrsL from './images/ClientLogos/Answrs.png'
import metalogiL from './images/ClientLogos/Metalogi.png'
import esmartL from './images/ClientLogos/Esmart.png'
import ifcL from './images/ClientLogos/IFC.png'
import leegalityL from './images/ClientLogos/LeegalityWhite.png'
import pepsiL from './images/ClientLogos/Pepsi.png'
import thomascookL from './images/ClientLogos/Thomascook.png'
import lihL from './images/ClientLogos/LIH.png'
import mindlerL from './images/ClientLogos/Mindler.png'
import datamindL from './images/ClientLogos/Datamind.png'
import auraL from './images/ClientLogos/Aura.svg'


import Mock1 from './images/ClientWork/Mock1.jpg'



export const works = [

    
    { 
        id: 1, 
        clientname: "ANSWRS Cenlub Systems", 
        industry:'Lubrication Systems' , 
        worktitle:" A 30+ year old manufacturing company rebrands its complex brand associations ", 
        src:answrsL,
        work:[
        { 
            catname: 'digital content', 
            worktitle:'This headline can be on digital content' ,
            src: Mock1,
            workimagesurl: 
                ['./images/ClientWork/Mock1.jpg' ,
                './images/ClientWork/Mock2.jpg', 
                './images/ClientWork/Mock3.jpg' ]

        
         },
         {
            catname: 'brand strategy', 
            worktitle:'This headline can be on brand strategy' ,
            src: Mock1,
            workimagesurl: 
                ['./images/ClientWork/Mock1.jpg' ,
                './images/ClientWork/Mock2.jpg', 
                './images/ClientWork/Mock3.jpg' ]
         }
        ]
                
      },

    { 
        id: 2, 
        clientname: "metalogi", 
        industry:'Lubrication Systems' , 
        worktitle:" Indian Logistics Packaging with an International Identity  ", 
        src: metalogiL,
        work:[
            { 
                catname: 'brand design', 
                worktitle:'This headline can be on brand design' ,
                src: Mock1,
                workimagesurl: 
                    ['./images/ClientWork/Mock1.jpg' ,
                    './images/ClientWork/Mock2.jpg', 
                    './images/ClientWork/Mock3.jpg' ]
    
            
             },
             {
                catname: 'user experience', 
                worktitle:'This headline can be on User experience' ,
                src: Mock1,
                workimagesurl: 
                    ['./images/ClientWork/Mock1.jpg' ,
                    './images/ClientWork/Mock2.jpg', 
                    './images/ClientWork/Mock3.jpg' ]
             }
            ]
    
    
    }, 

    
    { 
        id: 3, 
        clientname: "Datamind Technologies", 
        worktitle:" Exploring a cross cultural global identity for engineering services from the heart of Middle East  ", 
        src:datamindL,
        work:[
            { 
                catname: 'brand design', 
                worktitle:'This headline can be on brand design' ,
                src: Mock1,
                workimagesurl: 
                    ['./images/ClientWork/Mock1.jpg' ,
                    './images/ClientWork/Mock2.jpg', 
                    './images/ClientWork/Mock3.jpg' ]
    
            
             },
             {
                catname: 'user experience', 
                worktitle:'This headline can be on User experience' ,
                src: Mock1,
                workimagesurl: 
                    ['./images/ClientWork/Mock1.jpg' ,
                    './images/ClientWork/Mock2.jpg', 
                    './images/ClientWork/Mock3.jpg' ]
             }
            ]
    
    },


    { 
        id: 4, 
        clientname: "Aura Voyages", 
        worktitle:" A truly Indian Identity from an Indian lens ", 
        src:auraL,
        work:[
            { 
                catname: 'brand design', 
                worktitle:'This headline can be on brand design' ,
                src: Mock1,
                workimagesurl: 
                    ['./images/ClientWork/Mock1.jpg' ,
                    './images/ClientWork/Mock2.jpg', 
                    './images/ClientWork/Mock3.jpg' ]
    
            
             },
             {
                catname: 'user experience', 
                worktitle:'This headline can be on User experience' ,
                src: Mock1,
                workimagesurl: 
                    ['./images/ClientWork/Mock1.jpg' ,
                    './images/ClientWork/Mock2.jpg', 
                    './images/ClientWork/Mock3.jpg' ]
             }
            ]
    
    },


    { 
        id: 5, 
        clientname: "Jetways Travels", 
        worktitle:" A Dabur Group Travel Company gets a revamp after 3 decades  ", 
        src:jetwaysL,
        work:[
            { 
                catname: 'brand design', 
                worktitle:'This headline can be on brand design' ,
                src: Mock1,
                workimagesurl: 
                    ['./images/ClientWork/Mock1.jpg' ,
                    './images/ClientWork/Mock2.jpg', 
                    './images/ClientWork/Mock3.jpg' ]
    
            
             },
             {
                catname: 'user experience', 
                worktitle:'This headline can be on User experience' ,
                src: Mock1,
                workimagesurl: 
                    ['./images/ClientWork/Mock1.jpg' ,
                    './images/ClientWork/Mock2.jpg', 
                    './images/ClientWork/Mock3.jpg' ]
             }
            ]
    
    },

    
    { 
        id: 6, 
        clientname: "ANSWRS Cenlub System", 
        industry:'Lubrication Systems' , 
        worktitle:" A 30+ year old manufacturing company rebrands its complex brand associations ", 
        src:answrsL,
        work:[
            { 
                catname: 'brand design', 
                worktitle:'This headline can be on brand design' ,
                src: Mock1,
                workimagesurl: 
                    ['./images/ClientWork/Mock1.jpg' ,
                    './images/ClientWork/Mock2.jpg', 
                    './images/ClientWork/Mock3.jpg' ]
    
            
             },
             {
                catname: 'user experience', 
                worktitle:'This headline can be on User experience' ,
                src: Mock1,
                workimagesurl: 
                    ['./images/ClientWork/Mock1.jpg' ,
                    './images/ClientWork/Mock2.jpg', 
                    './images/ClientWork/Mock3.jpg' ]
             }
            ]
    
    
    },

    
    { 
        id: 7, 
        clientname: "Datamind Technologies",         
        worktitle:" A truly Indian Identity from an Indian lens ", 
        src:datamindL,
        work:[
            { 
                catname: 'brand design', 
                worktitle:'This headline can be on brand design' ,
                src: Mock1,
                workimagesurl: 
                    ['./images/ClientWork/Mock1.jpg' ,
                    './images/ClientWork/Mock2.jpg', 
                    './images/ClientWork/Mock3.jpg' ]
    
            
             },
             {
                catname: 'user experience', 
                worktitle:'This headline can be on User experience' ,
                src: Mock1,
                workimagesurl: 
                    ['./images/ClientWork/Mock1.jpg' ,
                    './images/ClientWork/Mock2.jpg', 
                    './images/ClientWork/Mock3.jpg' ]
             }
            ]
    
    },


        { 
            id: 8, 
            clientname: "ANSWRS Cenlub System", 
            industry:'Lubrication Systems' , 
            worktitle:" A 30+ year old manufacturing company rebrands its complex brand associations ", 
            src:answrsL  },
    
        
        { 
            id: 9, 
            clientname: "Datamind Technologies",         
            worktitle:" A truly Indian Identity from an Indian lens ", 
            src:datamindL }

    


];