
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/Home/Home.js';
import Header from './components/Header/Header.js';
import Impact from './components/Impact/Impact.js';
import About from './components/About/About.js';

import Contact from './components/Contact/Contact.js'
import BlogPg from './components/Blog/BlogPg.js'
import LoginPg from './components/Login/LoginPg.js'

import IntroPg from './components/Intro/IntroPg.js';




function App(){


  return (
    <div className="App">

      
          <Router>
              
              {/* <Header />  */}

                <Routes> 
                    {/* <Route  exact path='/' element={<Home />}  />  */}
                    {/* <Route  exact path='/' element={<Home />}  />  */}
                    {/* <Route path='/about' element={<About />} /> */}
                    {/* <Route path='/impact' element={<Impact />} /> */}
                    {/* <Route path='/contact' element={<Contact />} /> */}
                    {/* <Route path='/login' element={<LoginPg />} /> */}
                    {/* <Route path='/blog' element={<BlogPg />} /> */}
                    <Route path='/' element={<IntroPg />} />                  

                </Routes>

          </Router>

    </div>
  )
}

export default App
