import React from 'react'
import { useState } from 'react';
import './Styles.css'

import huLogo from './images/huLogo.svg'
import huFullLogo from './images/huFullLogo.svg'

import BoardGameImg from './images/BoardGameImgGrey1.png'
import BannerIntroImg from './images/BannerIntroImg3.png'
import GrowthImg from './images/GrowthImg2.png'
// import FillerImg from './images/FillerImg.png'
import DigitalContentImg from './images/DigitalContentImg.png'
import BrandDesignImg from './images/BrandDesignImg.gif'
import UIUXImg from './images/UIUXImg.gif'
import DigitalTransImg from './images/DigitalTransImg.png'; 

import earcL from './images/ClientLogos/Earc.png'
import encoreL from './images/ClientLogos/Encore.png'
import jetwaysL from './images/ClientLogos/Jetways.png'
import answrsL from './images/ClientLogos/Answrs.png'
import metalogiL from './images/ClientLogos/Metalogi.png'
import esmartL from './images/ClientLogos/Esmart.png'
import ifcL from './images/ClientLogos/IFC.png'
import leegalityL from './images/ClientLogos/LeegalityWhite.png'
import pepsiL from './images/ClientLogos/Pepsi.png'
import thomascookL from './images/ClientLogos/Thomascook.png'
import lihL from './images/ClientLogos/LIH.png'
import mindlerL from './images/ClientLogos/Mindler.png'
import datamindL from './images/ClientLogos/Datamind.png'
import auraL from './images/ClientLogos/Aura.svg'



import { works } from './Works';



const sections = [

  {id: 0, name: 'Brand Strategy', short:'Bs', shortHu:'hu', title: ''   },
  {id:1, name:'Brand Growth', short:'Bg', shortHu:'hu', title: '' },
  {id: 2, name: 'Brand Design', short:'Bd', shortHu:'hu', title: '' },
  {id:3, name: 'Brand Content', short:'Bc', shortHu:'hu', title: '' },
  {id: 4, name: 'Brand UI UX', short:'Ux', shortHu:'hu', title: '' },
  {id:5, name: 'Digital Technology', short:'Dt', shortHu:'hu', title: '' }


]





function IntroPg() {
  // State for link clicks in each section for a new slide to slide in for more content
  const [isActive, setIsActive] = useState(false);

  isActive ? document.body.style.overflow = "hidden" : document.body.style.overflow="auto";

  // State for the main up down slider tp move from one section to another
  // const[ secClicked, setSecClicked] = useState(0);


  // State for Capability selected and setting it 
  const [capab, setCapab ] = useState('none');


  function HuFullLogo (){

    return(
      <div className=''>

          <img alt="" className="RightSideHuBranding" src={huFullLogo} /> </div>
    

    )
  }

  function HuLogo(){

    return(
      
        <div className=''>

          <img alt="" className="RightSideHuBranding" src={huLogo} /> </div>
    
    )
  }

  function HuCapability(props){

    return(
            <div className='RightSideHuCapability'>
              {props.text}

            
            </div>
          )

    }

    function RSCaptionTop(props){
      return(
  
        <div className=''> {props.text} at human thing </div>
  
  
      )
  
    } 

  function FullScreenToggle(){

    
    return (
      <>  
        <div className='FullSCreenToggleCont'> <i class="material-icons" >fullscreen</i> </div>


      </>

    )


  }





   function MainSliderArrowBt(){
    


    return(

      <div className='IntroMainSecSlidersCont'> 
        <div className='MainSecSliderArrowBtTop'> <p>  &uarr; </p>  </div>
        <div className='MainSecSliderArrowBtBottom'> <p> &darr; </p>   </div>


      </div>


    )
  }

  function SingProjSliderArrowBt(){
    


    return(

      <div className='SingProjSlidersBtCont'> 
        <div className='SingProjSliderArrowBtTop'> <p>  &uarr; </p>  </div>
        <div className='SingProjSliderArrowBtBottom'> <p> &darr; </p>   </div>


      </div>


    )
  }

  function TopRightSide(){


    return(

      <div className='TopRightside'> 
      <div className='PastClientLogosCont'> 
        <div className="PastClientLogosDiv"> 

          

            <div className='PastClientLogos2'> <img className='PastClientLogosImg2' alt="" src={thomascookL} /> </div>
            <div className='PastClientLogos2'> <img className='PastClientLogosImg2' alt="" src={ifcL} /> </div>
            <div className='PastClientLogos2'> <img className='PastClientLogosImg2' alt="" src={esmartL } /> </div>
            

            <div className='PastClientLogos1'> <img className='PastClientLogosImg1' alt="" src={answrsL} /> </div>
            <div className='PastClientLogos1'>  <img className='PastClientLogosImg1' alt="" src={metalogiL} /> </div>
            <div className='PastClientLogos1'> <img className='PastClientLogosImg1' alt="" src={datamindL} /> </div> 
            
            <div className='PastClientLogos1'> <img className='PastClientLogosImg1' alt="" src={ pepsiL } /> </div> 
            <div className='PastClientLogos1'> <img className='PastClientLogosImg1' alt="" src={jetwaysL} /> </div>
            <div className='PastClientLogos1'> <img className='PastClientLogosImg1' alt="" src={ mindlerL } /> </div> 

            <div className='PastClientLogos2'> <img className='PastClientLogosImg2' alt="" src={ earcL } /> </div>
            <div className='PastClientLogos2'> <img className='PastClientLogosImg2' alt="" src={ encoreL } /> </div> 
            
            
            <div className='PastClientLogos2'> <img className='PastClientLogosImg2' alt="" src={ lihL } /> </div>


            
           
            {/* <div className='PastClientLogos'> <img className='PastClientLogosImg' alt="" src={ leegalityL } /></div> */}
           
      </div>

    </div>
  </div> 
    )
  }


  const [projIsClicked, setProjIsClicked] = useState(false);
    
    const [workId, setWorkId] = useState(0);
    


    const displayWorks = works.map( work => 

            <div className='SingProjDisp' key={work.id} 
                    onClick={ () => {

                      setProjIsClicked(true);
                      ExpandSingProjView();
                      setWorkId(work.id);

                    }
              
                      }>
                {/* <div className=' SingProjDispClientName' > {work.clientname} </div> */}

                <div className='AllProjViewTitle' > {work.worktitle} </div> 

                <div className='AllProjViewLogoCont' key={work.id}> 
                    <img alt="#" className='AllProjViewLogo' 
                    key={work.id}
                    src={work.src} /> </div>

                 


             </div>

    );


    function ExpandSingProjView(){


          
      const selectWork = works.filter( (work) =>       
        (work.id === workId )
    ) 

    const displaySelectWork = selectWork.map( work =>

      <div className="" key={work.id}> 
        <div className='WorkClientName'> {work.clientname}  </div> 

        <div>

        <div className='ProjViewTitle'> {work.worktitle} </div>
        {/* <div className='ProjViewLogoCont'> <img className='ProjViewLogo' alt="" src={work.src}  /></div> */}
        </div>

      </div>

    );


return (

        <div className=' ExpProjViewCont'> 
            <div className='ExpProjHeader'> 

              <p className='ProjBackBt' onClick={()=> { setProjIsClicked(false); } }> 
                  &larr; 
                  <p className='SingProjViewBackBt'>back </p> 
              </p>

              <SingProjSliderArrowBt  />
              

            </div> 

                <div className='AllProjDispCont' > 
                    
                     {displaySelectWork} 
                </div>
              
          





           </div>
)
}



  function SlideIn(){

    

    


    
    return (

       <div className='SlideInCont'> 

        <div className='SlideInNav'> 

              <p className='ArrowLeft'  
                onClick={ () => { setIsActive(false);  } 

                        }
                > &larr; 

              <p className="SlideInHomeBt"> home </p> </p>
              
              {/* <div className='SlideInBtMid'>

                <div className="SlideInTopBtCont SlideInBtMidLeft" 
                        // onClick={ () => { setCapab(''); }} 
                        >  
                    <p className='SlideInTopBt'> {capab} </p> </div>
              
                <div className="SlideInTopBtCont SlideInBtMidRight"> 
                    
                <p className='SlideInTopBt' 
                    onClick={ () => {
                          // set

                    }

                      }
                > {capab} Case Studies</p> </div>

              </div>   */}

              <div className='CapabSelect SlideInBtMid'> 

                      <select className=' SlideInTopBtCont SlideInBtMidLeft SlideInTopBt ' value={{capab}} > 
                          <option className='SlideInTopBtCont SlideInBtMidLeft'> {capab} </option>
                       </select>


                       <div className="SlideInTopBtCont SlideInBtMidRight"> 
                    
                    <p className='SlideInTopBt' 
                        onClick={ () => {
                              // set
    
                        }
    
                          }
                    > {capab} Case Studies</p> </div>
                      
              
               </div>



               <div className=' BuildBt '> <p className='SlideInTopBt'> Build </p>       </div>                             

            

         </div>



        <div className=''> 

             {/* <p className='SlideInHead'>  How brand strategy at human thing shapes your brand </p> */}

           
            {/* {displaySelectWork} */}

            {projIsClicked?   <ExpandSingProjView /> :  <div className='AllProjDispCont'>   {displayWorks} </div> }


                  
                    {/* {displayFilteredWork} */}

                    {/* <div className='AllProjDispCont'>    {displayWorks}  </div> */}



          </div>

       </div>


    
    )

  }

  function SlideOut(){



    return(
      <>
      </>

    )
  
  }


  function Brandlogos(){

    return(
        <div> 




        </div>

    )
  }



  
  return (
    <>
           <div className='LogoHuCont' > 

                <img alt="" src={huLogo} className='LogoHu' />
                <p className='LogoCaption'> Consulting</p>

            </div>

        <div className='IntroPgCont'>

          {/* new section */}

        <div className='IntroSlide'> 

       
              <div className='LeftSideDiv'>
            <p className='IntroSlide1Head'> We partner organisations on building strong Stakeholder - Brand relationships </p>
              {/* <p className='IntroSlide1Caption'> Intro </p> */}

              <div className='BoardGameImgCont'> 
                  <img alt="" className="BoardGameImg"
                   src={BannerIntroImg} />
              </div>

              </div>

            <TopRightSide />


          </div>

          {/* new Section */}

            <div className='IntroSlide1'> 
            <div className='LeftSideDiv'> 
              <p className='IntroSlide1Head'> Organisations become brands, with clarity </p>
              {/* <p className='IntroSlide1Caption' 
                  onClick={ () => {
                    setIsActive(true);  
                    SlideIn();

                    }
                  }
                  > Strategy for constant change &rarr; </p> */}

              {/* {isActive? <SlideIn />  : <SlideOut /> } */}

              <div className='BoardGameImgCont'> 
                  <img alt="" className="BoardGameImg"
                   src={BoardGameImg} />
              </div>
              </div>

              <div className='RightSideDivCont' 

                  onClick={ () => {
                    
                    setIsActive(true);  
                    setCapab('Brand Strategy');
                    SlideIn();


                    }
                  }
                            
              >
              <div className='RightSideDiv'>  

                <p className='RightSideDivHead'>
                
                <RSCaptionTop text="Brand Strategy" /> 

                <div className='StickR'>
                    <HuCapability text="Bs" />{' '} 
                    <HuCapability text="hu" />{' '} 
                    {/* <HuLogo  />  */}
                   
                   </div>
                   
                 
                
                </p> 

                {/* <p className='IntroSlide1Caption'>  Strategy for constant change </p> */}
                <p className='IntroSlide1Caption'>   View Cases  &rarr; </p>

               </div> 
               </div>

               {isActive? <SlideIn />  : <SlideOut /> }




            </div>

            {/* new Section */}

            <div className='IntroSlide1'> 
            <div className='LeftSideDiv'> 
                <p className='IntroSlide1Head'> Brand Growth in cluttered markets need Insights indepth & Metrics measurable</p>
                {/* <p className='IntroSlide1Caption'> &rarr;</p> */}
                

              <div className='BoardGameImgCont'> 
                  <img alt="" className="BoardGameImg"
                   src={GrowthImg} />
              </div>
              </div> 
              
              <div className='RightSideDivCont'

                        onClick={ () => {
                          setIsActive(true);  
                          setCapab('Brand Growth');
                          SlideIn();

                          }
                        }
              
              >
              <div className='RightSideDiv'>  
                  <p className='RightSideDivHead'> 
                  
                  <RSCaptionTop text="Brand Growth" /> 
                  <div className='StickR'>
                    {/* <HuLogo  />  */}
                    <HuCapability text="Bg" />{' '} 
                    <HuCapability text="hu" />{' '} 
                   </div>

                  
                   </p> 

                   {/* <p className='IntroSlide1Caption'> Only the relevant is easy to sell </p> */}
                   <p className='IntroSlide1Caption'>  View Cases &rarr; </p>
               </div> 
               </div>

               {isActive? <SlideIn      />  : <SlideOut    /> }
            

            </div>


            
            {/* new section */}

            <div className='IntroSlide1'> 
            <div className='LeftSideDiv'> 
                <p className='IntroSlide1Head'> Purpose-led Design does what nothing else does to human perception </p>
                {/* <p className='IntroSlide1Caption'> &rarr;</p> */}
              
                          
              <div className='BoardGameImgCont'> 
                  <img alt="" className="BoardGameImg"
                   src={BrandDesignImg} />
              </div>
              </div> 
              
              <div className='RightSideDivCont'
                                        onClick={ () => {
                          setIsActive(true); 
                          setCapab('Brand Design'); 
                          SlideIn();

                          }
                        }
              
              
              >
              <div className='RightSideDiv'>  
                <p className='RightSideDivHead'>
                
                <RSCaptionTop text="Brand Design" /> 
                <div className='StickR'>
                    {/* <HuLogo  />  */}
                    <HuCapability text="Bd" />{' '} 
                    <HuCapability text="hu" />{' '} 
                </div>

              
                
                </p> 


                  {/* <p className='IntroSlide1Caption'>  Brand Design Wins Quietly </p> */}
                  <p className='IntroSlide1Caption'>  View Cases &rarr; </p>

               </div> 
               </div>

               {isActive? <SlideIn   />  : <SlideOut /> }


            </div>

            {/* new Section */}

            <div className='IntroSlide1'> 
            <div className='LeftSideDiv'> 
              <p className='IntroSlide1Head'> Aware brands don't work hard for Branded Content, their purpose drives content  </p>
             
          
                <br />  <br />  <br /> 
              <div  className='BoardGameImgCont'> 
                  
                  <img alt="" className="BoardGameImg"
                   src={DigitalContentImg} />
              </div>
              </div>
              
              <div className='RightSideDivCont'
                                        onClick={ () => {
                                          setIsActive(true);  
                                          setCapab('Brand Content');
                                          SlideIn();
                
                                          }
                                        }
              
              >
              <div className='RightSideDiv'>  
              <p className='RightSideDivHead'>
                  <RSCaptionTop text="Brand Content" /> 
                  <div className='StickR'>
                    {/* <HuLogo  />  */}
                    <HuCapability text="Bc" />{' '} 
                    <HuCapability text="hu" />{' '} 
                   </div>
                   </p>

                  {/* <p className='IntroSlide1Caption'>  Owning Communities vs the Brand </p> */}
                  <p className='IntroSlide1Caption'>  View Cases  &rarr;</p>
               </div> 
               </div>

               {isActive? <SlideIn />  : <SlideOut /> }

            </div>

            {/* new section */}

            <div className='IntroSlide1'> 
            <div className='LeftSideDiv'> 

              <p className='IntroSlide1Head'> Good UX Design is Invisible </p>
              {/* <p className='IntroSlide1Caption'>   &rarr;  </p> */}
              
              <div className='BoardGameImgCont'> 
                  <img alt="" className="BoardGameImg"
                   src={UIUXImg} />
              </div>
              </div>
              
              <div className='RightSideDivCont'

                    onClick={ () => {
                      setIsActive(true);  
                      setCapab('UX User Experience');
                      SlideIn();

                      }
                    }
              
              >
              <div className='RightSideDiv'>  
                  <p className='RightSideDivHead'>
                  <RSCaptionTop text="Brand UI UX" /> 
                  <div className='StickR'>
                    {/* <HuLogo  />  */}
                    <HuCapability text="Ux" />{' '} 
                    <HuCapability text="hu" />{' '} 
                   </div>
                   </p>

                  {/* <p className='IntroSlide1Caption'>  Conversing with the Irrational Heart   </p> */}
                  <p className='IntroSlide1Caption'>  View Cases &rarr; </p>
               </div> 
               </div>

               {isActive? <SlideIn />  : <SlideOut /> }

            </div>



            {/* New Section */}

            <div className='IntroSlide1'> 
            <div className='LeftSideDiv'> 
              <p className='IntroSlide1Head'> Digital Technology enables your vision to take-on big problems at scale  </p>

             

              <div className='BoardGameImgCont'> 
                  <img alt="" className="BoardGameImg"
                   src={DigitalTransImg} />
              </div>

              </div>


            
              <div className='RightSideDivCont'
                                      onClick={ () => {
                                        setIsActive(true);  
                                        setCapab('Digital Tech Transformation');
                                        SlideIn();
              
                                        }
                                      }
              
              
              >
              <div className='RightSideDiv'>  

                  <p className='RightSideDivHead'>
                  <RSCaptionTop text="Digital Tech Transformation" /> 
                  <div className='StickR'>
                    {/* <HuLogo  />  */}
                    <HuCapability text="Dt" />{' '} 
                    <HuCapability text="hu" />{' '} 
                   </div>
                   </p>

                  {/* <p className='IntroSlide1Caption'>  Eliminate Tech Entry-Barriers   </p> */}
                  <p className='IntroSlide1Caption'>  View Cases  &rarr; </p>

               </div> 
               </div>

               {isActive? <SlideIn />  : <SlideOut /> }

            </div>





        
        </div>

       
        <MainSliderArrowBt />
        <FullScreenToggle />



    
    </>
  )
}


export default IntroPg
